var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.clientData && _vm.$can('update', 'clients'))?_c('div',[_c('b-media',{staticClass:"mb-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.clientData.display_name)+" ")])]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateForm)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Client Account ID","label-for":"clientname"}},[_c('validation-provider',{attrs:{"name":"clientname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"clientname","state":errors.length > 0 ? false:null,"readonly":true,"name":"clientname"},model:{value:(_vm.clientData.clientname),callback:function ($$v) {_vm.$set(_vm.clientData, "clientname", $$v)},expression:"clientData.clientname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Client Name","label-for":"display_name"}},[_c('validation-provider',{attrs:{"name":"display_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"display_name","readonly":true},model:{value:(_vm.clientData.display_name),callback:function ($$v) {_vm.$set(_vm.clientData, "display_name", $$v)},expression:"clientData.display_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Parent Client (leave blank for none)","label-for":"parent_clientname"}},[_c('validation-provider',{attrs:{"name":"parent_clientname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.clientsList,"reduce":function (val) { return val.value; },"clearable":true,"input-id":"parent_clientname"},model:{value:(_vm.clientData.parent_clientname),callback:function ($$v) {_vm.$set(_vm.clientData, "parent_clientname", $$v)},expression:"clientData.parent_clientname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Address","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address"},model:{value:(_vm.clientData.address),callback:function ($$v) {_vm.$set(_vm.clientData, "address", $$v)},expression:"clientData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Unit","label-for":"addressUnit"}},[_c('validation-provider',{attrs:{"name":"addressUnit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"addressUnit"},model:{value:(_vm.clientData.addressUnit),callback:function ($$v) {_vm.$set(_vm.clientData, "addressUnit", $$v)},expression:"clientData.addressUnit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"City","label-for":"city","rules":"required"}},[_c('validation-provider',{attrs:{"name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city"},model:{value:(_vm.clientData.city),callback:function ($$v) {_vm.$set(_vm.clientData, "city", $$v)},expression:"clientData.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Country","label-for":"country"}},[_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countryOptions,"reduce":function (val) { return val.value; },"input-id":"country","clearable":false},model:{value:(_vm.clientData.country),callback:function ($$v) {_vm.$set(_vm.clientData, "country", $$v)},expression:"clientData.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"State / Province","label-for":"province"}},[_c('validation-provider',{attrs:{"name":"province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.provinceOptions,"reduce":function (val) { return val.value; },"input-id":"province","clearable":false},model:{value:(_vm.clientData.province),callback:function ($$v) {_vm.$set(_vm.clientData, "province", $$v)},expression:"clientData.province"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Postal Code","label-for":"postal"}},[_c('validation-provider',{attrs:{"name":"postal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postal"},model:{value:(_vm.clientData.postal),callback:function ($$v) {_vm.$set(_vm.clientData, "postal", $$v)},expression:"clientData.postal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone"},model:{value:(_vm.clientData.phone),callback:function ($$v) {_vm.$set(_vm.clientData, "phone", $$v)},expression:"clientData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Admin Notes","label-for":"adminnote"}},[_c('validation-provider',{attrs:{"name":"adminnote"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"adminnote","rows":"3","max-rows":"6"},model:{value:(_vm.clientData.notes_admin),callback:function ($$v) {_vm.$set(_vm.clientData, "notes_admin", $$v)},expression:"clientData.notes_admin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Client Notes","label-for":"clientnote"}},[_c('validation-provider',{attrs:{"name":"clientnote"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"clientnote","rows":"3","max-rows":"6"},model:{value:(_vm.clientData.notes_client),callback:function ($$v) {_vm.$set(_vm.clientData, "notes_client", $$v)},expression:"clientData.notes_client"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Public Notes","label-for":"publicnote"}},[_c('validation-provider',{attrs:{"name":"publicnote"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"publicnote","rows":"3","max-rows":"6"},model:{value:(_vm.clientData.notes_public),callback:function ($$v) {_vm.$set(_vm.clientData, "notes_public", $$v)},expression:"clientData.notes_public"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"is_active"}},[_c('validation-provider',{attrs:{"name":"is_active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"is_active"},model:{value:(_vm.clientData.is_active),callback:function ($$v) {_vm.$set(_vm.clientData, "is_active", $$v)},expression:"clientData.is_active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" Save Changes ")])],1)],1)],1)]}}],null,false,2605398420)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }