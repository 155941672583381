<template>
  <div v-if="clientData && $can('update', 'clients')">
    <!-- Breadcrumb -->
    <b-row
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Client Account
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/cameras/list">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  to="/apps/clients/list"
                >
                  Client Account
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  :to="'/apps/clients/view/'+clientData.clientname"
                >
                  {{ clientData.display_name }}
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  Edit Client
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="clientData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching client data
      </h4>
      <div class="alert-body">
        No client found with this clientname.
      </div>
    </b-alert>
    <component :is="clientData === undefined ? 'div' : 'b-card'">
      <client-edit-tab-account
        :client-data="clientData"
        class="mt-2 pt-75"
      />
    </component>
  </div>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs, BCard, BAlert, BLink, BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'

import { API } from 'aws-amplify'

import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import clientStoreModule from '../clientStoreModule'
import ClientEditTabAccount from './ClientsEditTabAccount.vue'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,

    ClientEditTabAccount,
  },
  setup() {
    const clientData = ref(null)

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'

    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    console.log(router.currentRoute.params.clientname)
    const apiName = 'APIGateway'
    const path = `/client/${router.currentRoute.params.clientname}`
    // const path = '/client/'
    const myInit = { response: true }

    API.get(apiName, path, myInit)
      .then(response => {
        clientData.value = response.data
        clientData.value.status = clientData.value.is_active
      })
      .catch(error => {
        console.log(error)
        if (typeof error.response !== 'undefined' && error.response.status === 404) {
          clientData.value = undefined
        }
      })

    return {
      clientData,
    }
  },
}
</script>

<style>

</style>
