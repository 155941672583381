<template>
  <div v-if="clientData && $can('update', 'clients')">

    <!-- Media -->
    <b-media class="mb-2">
      <h4 class="mb-1">
        {{ clientData.display_name }}
      </h4>
    </b-media>

    <!-- Client Info: Input Fields -->
    <validation-observer
      ref="observer"
      #default="{ handleSubmit }"
    >
      <b-form
        @submit.prevent="handleSubmit(validateForm)"
      >
        <b-row>
          <b-col
            md="6"
          >
            <!-- Field: Client Account ID (clientname) -->
            <b-form-group
              label="Client Account ID"
              label-for="clientname"
            >
              <validation-provider
                #default="{ errors }"
                name="clientname"
                rules="required"
              >
                <b-form-input
                  id="clientname"
                  v-model="clientData.clientname"
                  :state="errors.length > 0 ? false:null"
                  :readonly="true"
                  name="clientname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Client Details -->
        <b-row>
          <!-- Client Full name -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Client Name"
              label-for="display_name"
            >
              <validation-provider
                #default="{ errors }"
                name="display_name"
                rules="required"
              >
                <b-form-input
                  id="display_name"
                  v-model="clientData.display_name"
                  :readonly="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Parent Client -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Parent Client (leave blank for none)"
              label-for="parent_clientname"
            >
              <validation-provider
                #default="{ errors }"
                name="parent_clientname"
              >
                <v-select
                  v-model="clientData.parent_clientname"
                  :options="clientsList"
                  :reduce="val => val.value"
                  :clearable="true"
                  input-id="parent_clientname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Address Info -->
        <b-row>
          <!-- Address -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Address"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                name="address"
                rules="required"
              >
                <b-form-input
                  id="address"
                  v-model="clientData.address"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Unit -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Unit"
              label-for="addressUnit"
            >
              <validation-provider
                #default="{ errors }"
                name="addressUnit"
              >
                <b-form-input
                  id="addressUnit"
                  v-model="clientData.addressUnit"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- City -->
          <b-col
            md="6"
          >
            <b-form-group
              label="City"
              label-for="city"
              rules="required"
            >
              <validation-provider
                #default="{ errors }"
                name="city"
              >
                <b-form-input
                  id="city"
                  v-model="clientData.city"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Country -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Country"
              label-for="country"
            >
              <validation-provider
                #default="{ errors }"
                name="country"
                rules="required"
              >
                <v-select
                  v-model="clientData.country"
                  :options="countryOptions"
                  :reduce="val => val.value"
                  input-id="country"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Province -->
          <b-col
            md="6"
          >
            <b-form-group
              label="State / Province"
              label-for="province"
            >
              <validation-provider
                #default="{ errors }"
                name="province"
                rules="required"
              >
                <v-select
                  v-model="clientData.province"
                  :options="provinceOptions"
                  :reduce="val => val.value"
                  input-id="province"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Postal -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Postal Code"
              label-for="postal"
            >
              <validation-provider
                #default="{ errors }"
                name="postal"
                rules="required"
              >
                <b-form-input
                  id="postal"
                  v-model="clientData.postal"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Phone -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="clientData.phone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Field: Admin Notes -->
        <b-row>
          <b-col
            md="12"
          >
            <b-form-group
              label="Admin Notes"
              label-for="adminnote"
            >
              <validation-provider
                #default="{ errors }"
                name="adminnote"
              >
                <b-form-textarea
                  id="adminnote"
                  v-model="clientData.notes_admin"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Field: Client Notes -->
        <b-row>
          <b-col
            md="12"
          >
            <b-form-group
              label="Client Notes"
              label-for="clientnote"
            >
              <validation-provider
                #default="{ errors }"
                name="clientnote"
              >
                <b-form-textarea
                  id="clientnote"
                  v-model="clientData.notes_client"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Field: Public Notes -->
        <b-row>
          <b-col
            md="12"
          >
            <b-form-group
              label="Public Notes"
              label-for="publicnote"
            >
              <validation-provider
                #default="{ errors }"
                name="publicnote"
              >
                <b-form-textarea
                  id="publicnote"
                  v-model="clientData.notes_public"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Field: Status -->
        <b-row>
          <b-col
            md="6"
          >
            <b-form-group
              label="Status"
              label-for="is_active"
            >
              <validation-provider
                #default="{ errors }"
                name="is_active"
                rules="required"
              >
                <v-select
                  v-model="clientData.is_active"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="2"
          >
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              type="submit"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { required, email } from '@validations'
import {
  BButton, BMedia, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    clientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      clientsList: [],
      // validation rules
      required,
      email,
    }
  },
  setup() {
    const roleOptions = store.state.appSettings.cognito_group
    const countryOptions = store.state.appSettings.countries
    const provinceOptions = store.state.appSettings.provinces
    const statusOptions = store.state.appSettings.is_active
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    // get the client list and setup a dataset for the parent client dd

    return {
      roleOptions,
      countryOptions,
      provinceOptions,
      statusOptions,
      refInputEl,
      previewEl,
    }
  },
  created() {
    // Set defaults for missing properties
    if (typeof this.clientData.notes_admin === 'undefined') {
      this.clientData.notes_admin = ''
    }
    if (typeof this.clientData.notes_client === 'undefined') {
      this.clientData.notes_client = ''
    }
    if (typeof this.clientData.notes_public === 'undefined') {
      this.clientData.notes_public = ''
    }
    // Get a list of clients for the Parent Client drop-down
    const qvars = {
      forceRefresh: store.state.clients.fetchingClientList,
      q: '',
      perPage: 10,
      page: 1,
      sortBy: 'display_name',
      sortDesc: true,
      status: null,
    }
    console.log(qvars)
    store
      .dispatch('app-client/fetchClients', qvars)
      .then(response => {
        const { clients } = response.data
        const tmp = []
        clients.forEach(item => {
          if (item.clientname !== this.clientData.clientname) {
            tmp.push({ label: item.display_name, value: item.clientname })
          }
        })
        this.clientsList = tmp
        console.log('clientsList')
        console.log(this.clientsList)
      })
      .catch(error => {
        console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching clients list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    validateForm() {
      // Temporary Add: Adding additional fields to data set that will be included in for at later date
      this.clientData.privatelabelname = ''
      console.log(this.clientData)

      this.$refs.observer.validate().then(success => {
        if (success) {
          console.log('form validated')
          console.log(this.clientData)
          store.dispatch('app-client/updateClient', this.clientData)
            .then(response => {
              console.log(response)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Client Updated',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              console.log(error)
              let theErrorStr = 'The following error(s) where received:\n'
              if (typeof error.response.data.errors !== 'undefined') {
                error.response.data.errors.forEach(item => {
                  theErrorStr += `${item.message}\n`
                })
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error adding client information.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: theErrorStr,
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
